/**
 * Imports
 */
import React from 'react';
import { WrestlerMap, StoryProps } from '../types'
import {
    Card,
    Image,
    Table,
    Jumbotron
} from 'react-bootstrap';
import { withRouter } from 'react-router';
import YouTube from 'react-youtube';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faTwitter,
    faYoutube,
    faGithub
} from '@fortawesome/free-brands-svg-icons';

function getVideoId(videoTag: string) {
    return videoTag.substring(videoTag.indexOf(":") + 1, videoTag.length);
}

function processLine(line: string) {
    if (line.match('jpg|png')) {
        return <span><Image src={line} fluid /><p /></span> 
    } else if (line.includes('video:')) {
        return <div className="embed-responsive embed-responsive-16by9"><YouTube videoId={getVideoId(line)} /></div>
    } else {
        return <span>{line}<p /></span> 
    }
}

function mappedWrestlerArray(wrestlerMap: WrestlerMap, wrestlers: string[]) {
    var mappedArray: string[] = new Array<string>();

    for (var i = 0; i < wrestlers.length; i++) {
        mappedArray[i] = wrestlerMap[wrestlers[i]].name;
    }

    return mappedArray;
} 

const StoryComponent: React.FC<StoryProps> = (props: StoryProps) => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);

    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <Jumbotron>
                        <h1 className="display-3">
                            News
                        </h1>
                    </Jumbotron>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-12">
                    <Card>
                        <Card.Body>
                            <Card.Text>
                            <h2>Results</h2>
                                {
                                    props.news[props.match.params.id].results.map(value => 
                                        processLine(value) 
                                    )
                                } 
                                <p />
                                <h2>Matches</h2>
                                <Table striped bordered hover>
                                    <thead className="table-primary">
                                        <th>#</th>
                                        <th>Match</th>
                                        <th>Championship</th>
                                        <th>Wrestlers</th>
                                        <th>Winner</th>
                                    </thead>
                                    <tbody>
                                        {
                                            props.news[props.match.params.id].matches.map((match, index) =>  
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{match.type}</td>
                                                <td>{match.championship}</td>
                                                <td>
                                                    {
                                                        mappedWrestlerArray(props.wrestlers, match.wrestlers).join(', ')
                                                    }
                                                </td>
                                                <td>{(match.winner === 'No Contest') ? match.winner : props.wrestlers[match.winner].name}</td>
                                            </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <br />
                </div>
                <div className="col-lg-3 col-md-2 col-sm-12">
                    <div className="card mb-3">
                        <h3 className="card-header">Follow Us</h3>
                        <div className="card-body">
                            <a href="https://twitter.com/squarecirclepi"><FontAwesomeIcon icon={faTwitter} size="3x" /></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <a href="https://www.youtube.com/feed/my_videos"><FontAwesomeIcon icon={faYoutube} size="3x" /></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <a href="https://github.com/ohanes/squarecirclepi"><FontAwesomeIcon icon={faGithub} size="3x" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default withRouter(StoryComponent);