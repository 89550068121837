import {WrestlerMap} from './types';

function filterRosterByPromotion(wrestlers: WrestlerMap, promotion: string) {
    var roster: WrestlerMap = {};
    for (let [key, value] of Object.entries(wrestlers)) {
        if (value.promotions.includes(promotion)) {
            Object.assign(roster, { [key]: value });
        }
    }
    return roster;
}

export default filterRosterByPromotion;