/**
 * Imports
 */
import React from 'react';
import { PromotionProps } from '../../types'
import {
  Tab,
  Tabs
} from 'react-bootstrap'
import RosterComponent from '../RosterComponent';
import DivisionsComponent from '../DivisionsComponent';
import ShowsComponent from '../ShowsComponent';
import RivalriesComponent from '../RivalriesComponent';
import './PromotionComponent.css';

const PromotionComponent: React.FC<PromotionProps> = (props: PromotionProps) => {
    return (
        <> 
            <h1>{props.promotion.name}</h1> 
            <Tabs defaultActiveKey="roster" id="uncontrolled-tab-example">
                <Tab eventKey="roster" title="Roster">
                    <RosterComponent wrestlers={props.wrestlers} />
                </Tab>
                <Tab eventKey="divisions" title="Divisions">
                    <DivisionsComponent divisions={props.promotion.divisions} wrestlers={props.wrestlers} />
                </Tab>
                <Tab eventKey="shows" title="Shows">
                    <ShowsComponent shows={props.promotion.shows} />
                </Tab>
                <Tab eventKey="rivalries" title="Rivalries">
                    <RivalriesComponent rivalries={props.promotion.rivalries} />
                </Tab>
            </Tabs> 
        </>
    );
}

export default PromotionComponent;