/**
 * Imports
 */
import React from 'react';
import { ShowsProps } from '../types'
import {
  Table,
} from 'react-bootstrap'

/**
 * Table widget
 * @param props Data properties
 */
const ShowsComponent: React.FC<ShowsProps> = (props: ShowsProps) => {
    return (
        <>
            <Table striped bordered hover>
                <thead className="table-primary">
                    <th>Name</th>
                    <th>Type</th>
                    <th>Date</th>
                </thead>
                <tbody>
                    {
                        Object.values(props.shows).map(show => (
                            <tr><td>{show.name}</td><td>{show.type}</td><td>{show.date}</td></tr>
                        ))
                    }
                </tbody>
            </Table>      
        </>
    );
}

export default ShowsComponent;
