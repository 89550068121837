/**
 * Imports
 */
import React from 'react';
import { RosterProps } from '../types'
import {
  Table
} from 'react-bootstrap'

/**
 * Table widget
 * @param props Data properties
 */
const RosterComponent: React.FC<RosterProps> = (props: RosterProps) => {
    return (
        <>
            <Table striped bordered hover>
                <thead className="table-primary">
                    <tr>
                        <th>Wrestler</th>
                        <th>Wins</th>
                        <th>Losses</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Object.values(props.wrestlers).map(wrestler => (
                            <tr><td>{wrestler.name}</td><td>{wrestler.wins}</td><td>{wrestler.losses}</td></tr>
                        ))

                    }
                </tbody>
                
            </Table>      
        </>
    );
}

export default RosterComponent;


