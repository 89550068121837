/**
 * Imports
 */
import React from 'react';
import {DivisionsProps} from '../types'
import {
  Table,
  Accordion,
  Card
} from 'react-bootstrap'

/**
 * Table widget
 * @param props Data properties
 */
const DivisionsComponent: React.FC<DivisionsProps> = (props: DivisionsProps) => {
    return (
        <>  
            <Accordion defaultActiveKey="0">
                {
                    (Object.values(props.divisions)).map((division, index) =>  
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey={index.toString()}>
                                <span className="unselectable">{division.name}</span>         
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={index.toString()}>
                                <Card.Body>
                                    <Table striped bordered hover>
                                        <thead className="table-primary">
                                            <th>Ranking</th>
                                            <th>Wrestler</th>
                                        </thead>
                                        <tbody>
                                            {
                                                division.rankings.map((wrestler, index) => <tr><td>{index === 0 ? "Champion" : index}</td><td>{props.wrestlers[wrestler].name}</td></tr>)
                                            }
                                        </tbody>
                                    </Table>      
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    )
                }
            </Accordion>
        </>
    );
}

export default DivisionsComponent;


