/**
 * Imports
 */
import {createStore} from 'redux';
import rootReducer from '../reducers/index';
import initialState from '../data.json';

// Create store
const store = createStore(rootReducer, initialState);

// Export
export default store;