/**
 * Imports
 */
import initialState from '../data.json';
import { LOAD_NEWS } from '../constants';

export function wrestlersReducer(state = initialState, action: any) {
    return state;
}

const newsletters = {
    "WedWk2JnYr1" : {
        "date": "Wednesday, Second Week of June, Year Two",
        "abstract": "Finn Balar goes after Zack Ryder and his WWE championship. Ohanes and Darby's rivalry heats up.",
        "results": [
            "https://pbs.twimg.com/media/EWRquVlUEAA0jq-?format=jpg&name=4096x4096",
            "This Monday's Raw proved to be rife with tension. As the title image shows, Brock Lesnar put an exclamation mark on his response to Triple H, with a hellacious F5.",
            "Finn Balor made his intentions known to Zack Ryder. The Prince is gunning for Ryder's WWE champsionship. Fresh off his win from last week's Raw, without a moment to rest. Heavy is the head the wears the crown.",
            "Dynamite was a couple's night out. Liv Morgan called out Hell's Favorite Harlot, Priscilla Kelly. This promo was rife with tension. The issues between these two is likely spilling over from their signficant others. After the show went off the air, Tony Khan let me know the Priscilla and Liv will be facing off in a steel cage match next week.",
            "The reasoning for the cage was attributed to keeping their boyfriends from interfering.",
            "While Liv's boyfriend, Ohanes, went toe-to-toe with Darby Allin. Darby has been racking up the wins lately and has a strong claim for the number one contendership for the AEW world championship. These two put on a clinic. Given that these two are cruiserweights, it was filled with high spots. The question now is, will Ohanes grant Darby a title shot at TakeOver: In Your House in two weeks? I reached out to Tony Khan on what the potential AEW World Championship bout would be. He stated that Ohanes will have to make an announcement during next week's Dynamite."
        ],
        "matches": [
            {
                "type": "Tag Team",
                "wrestlers": ["hardyma,wyattbr", "bucksyo" ],
                "winner": "hardyma,wyattbr"
            },
            {
                "type": "One on One",
                "wrestlers": ["fenixre", "mjf"],
                "winner": "mjf"
            },
            {
                "type": "One on One",
                "wrestlers": ["cobanco", "scurlma"],
                "winner": "No Contest"
            },
            {
                "type": "One on One",
                "wrestlers": ["jacksma", "aldisni"],
                "winner": "jacksma"
            },
            {
                "type": "One on One",
                "wrestlers": ["priesbe", "shidahi"],
                "winner": "priesbe"
            },
            {
                "type": "One on One",
                "wrestlers": ["dadiaoh", "allinda"],
                "winner": "dadiaoh"
            }
        ]
    },
    "WedWk2JnYr2" : {
        "date": "Wednesday, Second Week of June, Year Two",
        "abstract": "Finn Balar goes after Zack Ryder and his WWE championship. Ohanes and Darby's rivalry heats up.",
        "results": [
            "https://pbs.twimg.com/media/EWRquVlUEAA0jq-?format=jpg&name=4096x4096",
            "This Monday's Raw proved to be rife with tension. As the title image shows, Brock Lesnar put an exclamation mark on his response to Triple H, with a hellacious F5.",
            "Finn Balor made his intentions known to Zack Ryder. The Prince is gunning for Ryder's WWE champsionship. Fresh off his win from last week's Raw, without a moment to rest. Heavy is the head the wears the crown.",
            "Dynamite was a couple's night out. Liv Morgan called out Hell's Favorite Harlot, Priscilla Kelly. This promo was rife with tension. The issues between these two is likely spilling over from their signficant others. After the show went off the air, Tony Khan let me know the Priscilla and Liv will be facing off in a steel cage match next week.",
            "The reasoning for the cage was attributed to keeping their boyfriends from interfering.",
            "While Liv's boyfriend, Ohanes, went toe-to-toe with Darby Allin. Darby has been racking up the wins lately and has a strong claim for the number one contendership for the AEW world championship. These two put on a clinic. Given that these two are cruiserweights, it was filled with high spots. The question now is, will Ohanes grant Darby a title shot at TakeOver: In Your House in two weeks? I reached out to Tony Khan on what the potential AEW World Championship bout would be. He stated that Ohanes will have to make an announcement during next week's Dynamite."
        ],
        "matches": [
            {
                "type": "Tag Team",
                "wrestlers": ["hardyma,wyattbr", "bucksyo" ],
                "winner": "hardyma,wyattbr"
            },
            {
                "type": "One on One",
                "wrestlers": ["fenixre", "mjf"],
                "winner": "mjf"
            },
            {
                "type": "One on One",
                "wrestlers": ["cobanco", "scurlma"],
                "winner": "No Contest"
            },
            {
                "type": "One on One",
                "wrestlers": ["jacksma", "aldisni"],
                "winner": "jacksma"
            },
            {
                "type": "One on One",
                "wrestlers": ["priesbe", "shidahi"],
                "winner": "priesbe"
            },
            {
                "type": "One on One",
                "wrestlers": ["dadiaoh", "allinda"],
                "winner": "dadiaoh"
            }
        ]
    } 
};

function rootReducer(state = initialState, action: any) {
    switch (action.type) {
        case LOAD_NEWS:
            return {
                wrestlers: state.wrestlers,
                aew: state.aew,
                njpw: state.njpw,
                wwe: state.wwe,
                news: state.news,
                dates: state.dates,
                currentDate: state.currentDate
            }
    }
    return state;
}

export type RootState = ReturnType<typeof rootReducer>

// Export
export default rootReducer;