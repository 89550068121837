/**
 * Imports
 */
import React, { useState } from 'react';
import {
    Card,
    Jumbotron,
    Button
} from 'react-bootstrap';
import { NewsProps, News, Story } from '../types';
import { withRouter } from 'react-router';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faTwitter,
    faYoutube,
    faGithub
} from '@fortawesome/free-brands-svg-icons';
import Select from 'react-select';

/**
 * NewletterComponent
 * @param props Newsletter Properties
 */
const NewsComponent: React.FC<NewsProps> = (props: NewsProps) => {
    // Constants
    const CURRENT_YEAR  = "Yr1";
    const CURRENT_MONTH = "Jul";
    const DATE_REG_EX   = "(mon|tue|wed|thu|fri|sat|sun)Wk[0-9]+";
    const selectStyle  = {
        color: "Black",
        width: "500px",
        marginLeft: "17px"
    };
    const headerStyle  = {
        wordSpacing: "472px"
    };
    
    // Data members
    var newsArchive = Object.entries(props.news).reverse()
        .filter(([key, news]) =>
        key.match(constructRegEx(CURRENT_MONTH, CURRENT_YEAR)));
    const [newsState, setNewsState] = useState({
        news: newsArchive,
        selectedMonth: CURRENT_MONTH,
        selectedYear: CURRENT_YEAR 
    });
    const yearOptions = [
        { value : 'Yr1', label: 'Year One' },
        { value : 'Yr2', label: 'Year Two'}
    ];
    const monthOptions = [
        { value : 'Jan', label: 'January'   },
        { value : 'Feb', label: 'February'  },
        { value : 'Mar', label: 'March'     },
        { value : 'Apr', label: 'April'     },
        { value : 'May', label: 'May'       },
        { value : 'Jun', label: 'June'      },
        { value : 'Jul', label: 'July'      },
        { value : 'Aug', label: 'August'    },
        { value : 'Sep', label: 'September' },
        { value : 'Oct', label: 'October'   },
        { value : 'Nov', label: 'November'  },
        { value : 'Dec', label: 'December'  },
    ]

    // Event Handlers
    const yearHandleChange = e => {
        newsState.selectedYear = e.value;
    }
    const monthHandleChange = e => {
        newsState.selectedMonth = e.value;
    }

    function constructRegEx(month: string, year: string): string {
        return DATE_REG_EX + month + year; 
    }

    function filterNews(news: [string, Story][]): [string, Story][] {
        return news.filter(([key, news]) =>
            key.match(DATE_REG_EX + newsState.selectedMonth + newsState.selectedYear)
        );
    }

    function retrieveNewsURL(storyId) {
        return "/story/" + storyId;
    }

    function updateNews() {
        setNewsState({
            news: filterNews(Object.entries(props.news)),
            selectedMonth: newsState.selectedMonth,
            selectedYear: newsState.selectedYear 
        });
    }

    // Component Markup
    return (
        <> 
            <div className="row">
                <div className="col-lg-12">
                    <Jumbotron>
                        <h1 className="display-3">
                            Newsletters
                        </h1>
                    </Jumbotron>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-12">
                    <Card>
                        <Card.Body>
                            <Card.Title as="h4" style={headerStyle}>Year Month</Card.Title>
                            <Card.Text>
                                <div className="row">
                                    <div style={selectStyle}>
                                        <Select options={yearOptions} onChange={yearHandleChange} />
                                    </div>
                                    <div style={selectStyle}>
                                        <Select options={monthOptions} onChange={monthHandleChange} />
                                    </div>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div>
                                        <Button variant="primary" onClick={updateNews}>Filter</Button>
                                    </div>
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <br />
                    {
                        newsState.news.map(([key, story]) => (
                            <>
                                <Card>
                                    <Card.Body>
                                        <Card.Title as="h4">{story.date}</Card.Title>
                                        <Card.Text>{story.abstract}</Card.Text>
                                        <Card.Link as={Link} to={retrieveNewsURL(key)}>Read More</Card.Link>
                                    </Card.Body>
                                </Card>
                                <br />
                            </>
                        ))
                    }
                </div>
                <div className="col-lg-3 col-md-2 col-sm-12">
                    <div className="card mb-3">
                        <h3 className="card-header">Follow Us</h3>
                        <div className="card-body">
                            <a href="https://twitter.com/squarecirclepi"><FontAwesomeIcon icon={faTwitter} size="3x" /></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <a href="https://www.youtube.com/feed/my_videos"><FontAwesomeIcon icon={faYoutube} size="3x" /></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <a href="https://github.com/ohanes/squarecirclepi"><FontAwesomeIcon icon={faGithub} size="3x" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default withRouter(NewsComponent);
