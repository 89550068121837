/**
 * Imports
 */
import React from 'react';
import { RivalriesProps } from '../types'
import {
  Table,
} from 'react-bootstrap'

/**
 * Table widget
 * @param props Data properties
 */
const RivalriesComponent: React.FC<RivalriesProps> = (props: RivalriesProps) => {
    return (
        <>
            <Table striped bordered hover>
                <thead className="table-primary">
                    <th>Rivals</th>
                    <th>Type</th>
                    <th>Intensity</th>
                    <th>Weeks Left</th>
                </thead>
                <tbody>
                    {
                        Object.values(props.rivalries).map(rivalry => (
                            <tr><td>{rivalry.rivals}</td><td>{rivalry.type}</td><td>{rivalry.intensity}</td><td>{rivalry.weeksLeft}</td></tr>
                        ))
                    }
                </tbody>
            </Table>      
        </>
    );
}

export default RivalriesComponent;
