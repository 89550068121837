/**
 * Imports 
 */
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faTwitter,
    faYoutube,
    faGithub
} from '@fortawesome/free-brands-svg-icons';
import YouTube from 'react-youtube';
import {
    Jumbotron,
    Card,
    Image
} from 'react-bootstrap';
import {Link} from 'react-router-dom';

/**
 * CSS styles
 */
const podcastStyle = {
    width: "100%"
};

/**
 * Home function component
 */
const Home: React.FC = () => {
    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <Jumbotron>
                        <h1 className="display-3">
                            2K Universe Mode
                            <p className="lead text-muted">Featuring AEW, NJPW, and WWE</p>
                        </h1>
                    </Jumbotron>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-12">
                    <Card>
                        <Card.Body>
                            <Card.Title as="h4">Podcast</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">April 26, 2020</Card.Subtitle>
                            <audio controls style={podcastStyle}>
                                <source src="https://www.dropbox.com/s/0srg44r0qgmerl2/squaringTheCircleRadioInYourHouse.m4a?raw=1" type="audio/mpeg" />
                            </audio>
                            <br />
                            <Card.Text>Squaring The Circle podcast discussing NXT TakeOver: In Your House.</Card.Text>
                        </Card.Body>
                    </Card>
                    <br />
                    <Card>
                        <Card.Body>
                            <Card.Title as="h4">Newsletter</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Friday, First Week of July</Card.Subtitle>
                            <Card.Text>
                                <Image src="https://pbs.twimg.com/media/EbuuZ7lUcAEOX3E?format=jpg" fluid />
                                <br /><br />
                                It was a 90s flashblack tonight at In Your House. 
                            </Card.Text>
                            <Card.Link as={Link} to="/newsletter/sunWk4JnYr1">Read More</Card.Link>
                        </Card.Body>
                    </Card>
                    <br />
                    <Card>
                        <Card.Body>
                            <Card.Title as="h4">Newsletter</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Friday, First Week of July</Card.Subtitle>
                            <Card.Text>
                                <div className="embed-responsive embed-responsive-16by9">
                                    <YouTube videoId="WUBjn6t6DL0" />
                                </div>
                                <br /><br />
                                New Japan this week was red hot as the rivalry between Omega and Ibushi headed up. 
                            </Card.Text>
                            <Card.Link as={Link} to="/newsletter/wedWk4JnYr1">Read More</Card.Link>
                        </Card.Body>
                    </Card>
                    <br />
                    <Card>
                        <Card.Body>
                            <Card.Title as="h4">Newsletter</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Friday, First Week of July</Card.Subtitle>
                            <Card.Text>
                                <div className="embed-responsive embed-responsive-16by9">
                                    <YouTube videoId="WUBjn6t6DL0" />
                                </div>
                                <br /><br />
                                The AEW go home show for In Your House saw Ohanes attacking his challenger, Darby. 
                            </Card.Text>
                            <Card.Link as={Link} to="/newsletter/monWk4JnYr1">Read More</Card.Link>
                        </Card.Body>
                    </Card>
                    <br />
                    <Card>
                        <Card.Body>
                            <Card.Title as="h4">Newsletter</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Friday, First Week of July</Card.Subtitle>
                            <Card.Text>
                                <Image src="https://pbs.twimg.com/media/Ea_S5pTU8AA1232?format=jpg&name=4096x4096" fluid />
                                <br /><br />
                                The WWE go home show for In Your House went off like gangbusters, as Finn Balor takes on Braun Strowman and John Cena takes on WWE Champion Zack Ryder. 
                            </Card.Text>
                            <Card.Link as={Link} to="/newsletter/monWk4JnYr1">Read More</Card.Link>
                        </Card.Body>
                    </Card>
                    <br />
                    <Card>
                        <Card.Body>
                            <Card.Title as="h4">Newsletter</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Friday, Fourth Week of June</Card.Subtitle>
                            <Card.Text>
                                <Image src="https://pbs.twimg.com/media/EagTvPoVcAA5NUz?format=jpg&name=4096x4096" fluid />
                                <br /><br />
                                The Bullet Club Civil War heats up as Matt Jackson faces Jay White. The Golden Lovers are headed to a breakup as Kenny Omega comes for Kota Ibushi's IGWP Heavyweight championship.
                            </Card.Text>
                            <Card.Link as={Link} to="/newsletter/friWk4JnYr1">Read More</Card.Link>
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-lg-3 col-md-2 col-sm-12">
                    <div className="card mb-3">
                        <h3 className="card-header">Follow Us</h3>
                        <div className="card-body">
                            <a href="https://twitter.com/squarecirclepi"><FontAwesomeIcon icon={faTwitter} size="3x" /></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <a href="https://www.youtube.com/feed/my_videos"><FontAwesomeIcon icon={faYoutube} size="3x" /></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <a href="https://github.com/ohanes/squarecirclepi"><FontAwesomeIcon icon={faGithub} size="3x" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;