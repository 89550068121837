/*
 * Imports
 */
import React                      from 'react';
import PromotionComponent         from './pages/promotion/PromotionComponent';
import NewsComponent              from './pages/NewsComponent';
import StoryComponent             from './pages/StoryComponent';
import Home                       from './Home';
import { connect }                from 'react-redux';
import { AppProps, NewsCriteria } from './types';
import { AEW, NJPW, WWE}          from './constants';
import filterRosterByPromotion    from './filters';
import {
  Container,
  Navbar,
  Nav,
  NavItem,
  Form,
  FormControl,
  Button,
} from 'react-bootstrap'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from 'react-router-dom';
import './App.css';

/**
 * App function component
 */
const App: React.FC<AppProps> = (props: AppProps) => {
  return (
      <Router>
        <Navbar className="navbar-dark fixed-top sticky-nav" bg="primary" expand="lg">
          <Navbar.Brand href="home">Squaring The Circle</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="navbar-nav mr-auto">
              <NavItem>
                <Nav.Link as={Link} to='/home' className="nav-link">Home</Nav.Link>
              </NavItem>
              <NavItem>
                <Nav.Link as={Link} to='/news' className="nav-link">News</Nav.Link>
              </NavItem>
              <NavItem>
                <Nav.Link as={Link} to='/aew' className="nav-link">AEW</Nav.Link>
              </NavItem>
              <NavItem>
                <Nav.Link as={Link} to='/njpw' className="nav-link">NJPW</Nav.Link>
              </NavItem>
              <NavItem>
                <Nav.Link as={Link} to='/wwe' className="nav-link">WWE</Nav.Link>
              </NavItem>
            </Nav>
            <Form inline>
              <FormControl type="text" placeholder="Search" className="mr-sm-2" />
              <Button variant="outline-light">Search</Button>
            </Form>
          </Navbar.Collapse>
        </Navbar>
        <Container className="p-3" fluid>
          <Switch>
            <Route path="/aew">
              <PromotionComponent wrestlers={filterRosterByPromotion(props.wrestlers, AEW)} promotion={props.aew} />
            </Route>
            <Route path="/njpw">
              <PromotionComponent wrestlers={filterRosterByPromotion(props.wrestlers, NJPW)} promotion={props.njpw} />
            </Route>
            <Route path="/wwe">
              <PromotionComponent wrestlers={filterRosterByPromotion(props.wrestlers, WWE)} promotion={props.wwe} />
            </Route>
            <Route path="/home">
              <Home />
            </Route>
            <Route path="/news">
              <NewsComponent news={props.news} wrestlers={props.wrestlers} dates={props.dates} />
            </Route>
            <Route path="/story/:id?">
              <StoryComponent news={props.news} wrestlers={props.wrestlers} dates={props.dates} />
            </Route>
            <Route path="/">
              <Redirect to="/home" />
            </Route>
          </Switch>
        </Container>
      </Router>
  );
}

const mapStateToProps = (state: any) => ({
  wrestlers: state.wrestlers,
  aew: state.aew,
  njpw: state.njpw,
  wwe: state.wwe,
  news: state.news,
  dates: state.dates
});

const AppContainer = connect(mapStateToProps)(App);
export default AppContainer;